module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":" Microlino Store Doesburg","short_name":"MicrolinoStoreDoesburg","start_url":"/?pwa=true","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ffa9c6ca536605501a3a9f0cb301bfcb"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://bb2873a9071fbfa7cdbdba50f97e281a@o1430399.ingest.us.sentry.io/4507055302180864","tracesSampleRate":1,"release":"gatsby@2025-03-25T20:26:53.537Z","environment":"production","enabled":true,"debug":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(min-width: 36rem)","md":"(min-width: 48rem)","lg":"(min-width: 62rem)","xl":"(min-width: 75rem)"}},
    },{
      plugin: require('../../../packages/gatsby-theme-menus/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-apolloclient/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/acm/gatsby-browser.js'),
      options: {"plugins":[],"core":{"headers":{"/voorraad/*":["Content-Security-Policy: frame-ancestors 'self' https://crm.walcu.com;"]},"sitemapExcludes":["/bedankt/**/*","/voorraad/model/**/*","/voorraad/aanvraag/model/**/*"],"sourceStrapi":{"themes":{"autochat":true,"vehicles":true,"rating":true,"blog":true,"pagebuilder":true,"messagebubble":true,"faq":true,"documents":true,"spincar":true,"vehicleLease":true}}}},
    },{
      plugin: require('../../../packages/gatsby-theme-pagebuilder/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-messagebubble/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/doesburg/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-autochat/gatsby-browser.js'),
      options: {"plugins":[],"assistantId":"6bb89bbd-305d-4e0d-81d3-11060e7666c5","accessToken":"711fc45f2d33b27b78a009685967c376456159ffe2b95c8cbdc991270b03a7aedbc3a457a13371baff867c531ee6c0c49501757234cac82a73e84f77"},
    },{
      plugin: require('../../../packages/gatsby-theme-times-modal-opened/gatsby-browser.js'),
      options: {"plugins":[],"maxTimesModalOpenedAllowed":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7J3LL6M69C"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
